import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Utilities {

  constructor() { }

  public static ConcatenateStrings(strings: string[], noItems: string = 'No Items Found'): string {

    if (strings.length === 0) {
      return noItems;
    }

    if (strings.length === 1) {
      return strings[0];
    }

    return strings.slice(0, -1).join(', ') + ' and ' + strings.slice(-1);
  }
}
